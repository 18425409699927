import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from '../../deal';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealIndexDocuments extends BaseRoute {
    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Index / Documents');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as ModelFrom<Deal>;
        await deal.hasMany('actionItems').reload();
        return deal;
    }
}
