import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import OpsTeamUser from 'portal/models/ops-team-user';
import { OpsTeamUserRoles } from 'portal/models/ops-team-user';

interface YourEmporaTeamArgs {
    deal: Deal;
    goToMessages: () => void;
    opsTeamUsers: OpsTeamUser[];
}

export default class YourEmporaTeam extends Component<YourEmporaTeamArgs> {
    get clientSuccessManager() {
        return this.args.opsTeamUsers.filterBy('role', OpsTeamUserRoles.CLIENT_SUCCESS_MANAGER)[0];
    }

    get disbursementSpecialist() {
        return this.args.opsTeamUsers.filterBy('role', OpsTeamUserRoles.DISBURSEMENT_SPECIALIST)[0];
    }

    get escrowOfficer() {
        return this.args.opsTeamUsers.filterBy('role', OpsTeamUserRoles.ESCROW_OFFICER)[0];
    }

    get phoneNumber(){
        if (this.clientSuccessManager?.phoneNumber) {
            return this.clientSuccessManager.phoneNumber;
        }
        else {
            return '6144077969';
        }
    }
    get titleCurative() {
        return this.args.opsTeamUsers.filterBy('role', OpsTeamUserRoles.TITLE_CURATIVE)[0];
    }
}
