import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import PropertyInformationController from 'portal/controllers/protected/deal/onboarding/property-information';

export default class ProtectedDealOnboardingSellerPropertyInformation extends PropertyInformationController {
    async onNext() {
        const deal = this.model.deal;
        const actionItems = await deal.actionItems;
        const sellerPropertyDetailsActivity = actionItems?.find((a: ActionItem) =>
            a.templateKey == ActionItemTemplateKeys.SELLER_PROPERTY_DETAILS
        );

        if (sellerPropertyDetailsActivity) {
            sellerPropertyDetailsActivity.hasCompleted = true;
            await sellerPropertyDetailsActivity.save();
            this.router.transitionTo('protected.deal.action-item-completion', sellerPropertyDetailsActivity.id);
        } else {
            this.router.transitionTo('protected.deals');
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/onboarding/seller-property-information': ProtectedDealOnboardingSellerPropertyInformation;
  }
}
