import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import DocumentModel from 'portal/models/document';
import EmporaDocumentUpload from 'portal/components/empora/document-upload/component';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface DealDocumentsArgs {
    deal: Deal;
}

export const DealDocsErrorMessage =
    'Some files are still processing, please wait or try again later...';

export default class DealDocuments extends Component<DealDocumentsArgs> {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @tracked
        documents: Array<DocumentModel> = [];

    @tracked
        docsError?: string;

    get deal() {
        return this.args.deal;
    }

    @task
    async getDocuments() {
        try {
            this.documents = (
                await this.store.query('document', {
                    deal_id: this.args.deal.id
                })
            ).toArray();
        } catch (e) {
            this.docsError = DealDocsErrorMessage;
            throw e;
        }
    }

    @task
    async saveDocument(doc: DocumentModel) {
        this.analytics.trackEvent('Deal Document Uploaded');
        await doc.save();
    }

    @action
    onDelete(document: DocumentModel) {
        this.documents.removeObject(document);
    }

    @action
    async onUpload(doc: DocumentModel, documentUpload: EmporaDocumentUpload) {
        doc.deal = this.args.deal;
        try {
            await taskFor(this.saveDocument).perform(doc);
        } catch (e) {
            return;
        } finally {
            documentUpload.clearDocument();
        }
        this.documents.pushObject(doc);
    }

    @action
    onDownload(document: DocumentModel) {
        this.analytics.trackEvent('Deal Document Downloaded', {
            documentId: document.id,
            documentName: document.name
        });
    }
}
