import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealRoute } from '../../deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingSellerClosingPreferences extends BaseRoute {
    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Seller Closing Preferences');
    }

    async model(): Promise<Deal> {
        const parentModel = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;

        return parentModel;
    }
}
