import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface DealActionItemArgs {
    actionItems: ActionItem[];
}

export function componentPath(name: string) {
    return `deal/action_items/${name}`;
}

export function routeEntryPoint(name: string) {
    return `protected.deal.${name}`;
}

export const ActionItemMap = new Map<ActionItemTemplateKeys, Map<string, string>>();
ActionItemMap.set(
    ActionItemTemplateKeys.ASSIGNOR_EARNEST_MONEY_METHOD,
    new Map([['component', componentPath('earnest-money-collection')],
        ['route', routeEntryPoint('earnest-money.selection')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.ASSIGNOR_INFORMATION_AND_PAYMENT_INSTRUCTIONS,
    new Map([['component', componentPath('assignor-information-and-payment-instructions')],
        ['route', routeEntryPoint('titleholder-information.select-titleholder')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.PAYOFF_AUTHORIZATION,
    new Map([['component', componentPath('payoff-authorization')],
        ['route', routeEntryPoint('payoff-authorization')],
        ['routeRequiresActivityId', 'true']])
);
ActionItemMap.set(
    ActionItemTemplateKeys.BORROWER_ONBOARDING,
    new Map([['component', componentPath('borrower-onboarding')],
        ['route', routeEntryPoint('onboarding')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.BUYER_CLOSING_PREFERENCES_AND_COVERAGES,
    new Map([['component', componentPath('buyer-closing-preferences-and-coverages')],
        ['route', routeEntryPoint('buyer-closing-preferences-and-coverages')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.BUYER_CLOSING_REQUEST,
    new Map([['component', componentPath('closing-request')],
        ['route', routeEntryPoint('closing-request')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.NON_SUBMITTER_CLOSING_REQUEST,
    new Map([['component', componentPath('closing-request')],
        ['route', routeEntryPoint('closing-request')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.SELLER_CLOSING_REQUEST,
    new Map([['component', componentPath('closing-request')],
        ['route', routeEntryPoint('closing-request')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.SUBMITTER_CLOSING_REQUEST,
    new Map([['component', componentPath('closing-request')],
        ['route', routeEntryPoint('closing-request')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.BUYER_EARNEST_MONEY_METHOD,
    new Map([['component', componentPath('earnest-money-collection')],
        ['route', routeEntryPoint('earnest-money.selection')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.BUYER_ONBOARDING,
    new Map([['component', componentPath('onboarding')],
        ['route', routeEntryPoint('onboarding')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION,
    new Map([['component', componentPath('buyer-titleholder-and-financing-information')],
        ['route', routeEntryPoint('buyer-titleholder-and-financing-information')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.BUYER_WIRE_INSTRUCTIONS,
    new Map([['component', componentPath('buyer-wire')],
        ['route', routeEntryPoint('buyer-wire-instructions')],
        ['routeRequiresActivityId', 'true']])
);
ActionItemMap.set(
    ActionItemTemplateKeys.DOWNLOAD_EMPORA_WIRE_INSTRUCTIONS,
    new Map([['component', componentPath('buyer-wire')],
        ['route', routeEntryPoint('buyer-wire-instructions')],
        ['routeRequiresActivityId', 'true']])
);
ActionItemMap.set(
    ActionItemTemplateKeys.REVIEW_YOUR_TITLE_WORK,
    new Map([['component', componentPath('commitment-review')],
        ['route', routeEntryPoint('commitment-review')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.COMPLETE_TITLEHOLDER_INFORMATION,
    new Map([['component', componentPath('complete-titleholder-information')],
        ['route', routeEntryPoint('titleholder-information.preference')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.PROVIDE_RENTAL_INFORMATION,
    new Map([['component', componentPath('provide-rental-information')],
        ['route', routeEntryPoint('provide-rental-information')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.SELLER_CLOSING_PREFERENCES,
    new Map([['component', componentPath('seller-closing-preferences')],
        ['route', routeEntryPoint('onboarding.seller-closing-preferences')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.SELLER_ONBOARDING,
    new Map([['component', componentPath('onboarding')],
        ['route', routeEntryPoint('onboarding')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.SELLER_PROPERTY_DETAILS,
    new Map([['component', componentPath('seller-property-information')],
        ['route', routeEntryPoint('onboarding.seller-property-information')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS,
    new Map([['component', componentPath('seller-titleholder-information')],
        ['route', routeEntryPoint('onboarding.seller-titleholder-info')]])
);
ActionItemMap.set(
    ActionItemTemplateKeys.REVIEW_SETTLEMENT_STATEMENT,
    new Map([['component', componentPath('settlement-statement-review')],
        ['route', routeEntryPoint('settlement-statement-review')]])
);

export default class DealActionItems extends Component<DealActionItemArgs> {
    @service intl!: IntlService;

    @service
        router!: RouterService;

    actionItemMap = ActionItemMap;

    get sortedActionItems() {
        return this.args.actionItems.sortBy('dueAt');
    }

    @action
    onActionItemStart(actionItem: ActionItem) {
        if (!actionItem.isDeleted) {
            actionItem.hasStarted = true;
            actionItem.save();
        }

        const actionItemMapEntry = ActionItemMap.get(actionItem.templateKey as ActionItemTemplateKeys);
        const route = actionItemMapEntry?.get('route');

        if (route) {
            if (actionItemMapEntry?.has('routeRequiresActivityId')) {
                this.router.transitionTo(route, actionItem.id);
            } else {
                this.router.transitionTo(route);
            }
        }
    }

    @action
    onRequestAChange(actionItem: ActionItem) {
        const messageText = this.intl.t(
            'components.deal.action_items.request_a_change',
            { actionItem: actionItem.description?.toLowerCase() }
        );

        this.router.transitionTo('protected.deal.index.messages', {
            queryParams: { newMessageText: messageText }
        });
    }
}
