import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealRoute } from '../../deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingSellerPropertyInformation extends BaseRoute {
    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Seller Property Information');
    }

    async model() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;

        this.dealSide.initialize(deal.id);

        const property = await deal.property;
        const creditLines = await property?.creditLinesForDealSide(this.dealSide.for(deal.id));

        return {
            deal,
            property,
            creditLines
        };
    }

    @action
    invalidateModel(): void {
        this.refresh();
    }
}
