import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import DealMessage from 'portal/models/deal-message';
import EmporaServer from 'portal/services/empora-server';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import WebSocketService from 'portal/services/web-socket';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface DealMessagesArgs {
    currentUser: User;
    deal: Deal;
    messages: DealMessage[];
    newMessageText?: string;
}

export default class DealMessages extends Component<DealMessagesArgs> {
    @service
    declare analytics: Analytics;

    @service
    declare emporaServer: EmporaServer;

    @service
    declare store: Store;

    @service
    declare webSocket: WebSocketService;

    @tracked
        sortedAndFilteredDealMessages: DealMessage[] = [];

    @tracked
        initialDealMessages: DealMessage[];

    @tracked
    declare newDealMessage?: DealMessage;

    newMessageText?: string;

    constructor(owner: unknown, args: DealMessagesArgs) {
        super(owner, args);

        this.newMessageText = this.args.newMessageText;

        this.initialDealMessages = this.args.messages;
        this.sortAndFilterDealMessages();

        this.webSocket.createSubscription(
            'Deals::Channels::MessageUpdatesForPortalChannel',
            (message: any) => { this.receivedMessageUpdate(message); },
            { deal_id: this.args.deal.id, deal_role: this.args.deal.actorRole }
        );
    }

    @action
    createNewDealMessage() {
        if (this.args.newMessageText) {
            this.newDealMessage = this.store.createRecord('deal-message', {
                deal: this.args.deal,
                messageText: this.newMessageText
            });
        } else {
            this.newDealMessage =
                this.args.deal.messages?.filterBy('id', null).firstObject ||
                this.store.createRecord('deal-message', {
                    deal: this.args.deal
                });
        }
    }

    @action
    scrollToBottom(element: HTMLDivElement) {
        element.scrollTop = element.scrollHeight;
    }

    @task
    async markAsRead(): Promise<void> {
        this.args.deal.hasUnreadMessages = false;
        await this.args.deal.save();
    }

    @task
    async sendMessage() {
        if (!this.newDealMessage) {
            return;
        }

        await this.newDealMessage.save();
        this.newMessageText = '';
        this.createNewDealMessage();
    }

    @task
    async downloadDocument(document: any): Promise<void> {
        const documentDownloadRequest = this.store.createRecord(
            'document-download-request',
            {
                document: document,
                deal: this.args.deal
            }
        );

        // This needs to be instantiated before async behavior in order to not be blocked by Safari as a pop-up.
        // See https://stackoverflow.com/a/39387533
        const windowOpenReference = window.open();

        await documentDownloadRequest.save();

        const { url } = documentDownloadRequest;

        if (url && windowOpenReference) {
            this.analytics.trackEvent('Deal Message - Document Download Clicked');
            windowOpenReference.location = url;
        }
    }

    receivedMessageUpdate(message: any) {
        // Message passed in is cloned because pushPayload below updates
        // the message passed into it, causing the subsequent times through this function
        // to not work.
        const clonedMessage = JSON.parse(JSON.stringify(message));
        this.store.pushPayload(clonedMessage);

        const updatedMessage = this.store.peekRecord('deal-message', message.data.id);
        if (updatedMessage) {
            updatedMessage.isMyMessage = (updatedMessage.createdBy?.toLowerCase() === this.args.currentUser.email?.toLowerCase());
            if (updatedMessage.deletedAt) {
                this.initialDealMessages = this.initialDealMessages.filter((message) => message.id !== updatedMessage.id);
            } else {
                if (!this.initialDealMessages.some((initialDealMessage) => initialDealMessage.id === updatedMessage.id)) {
                    this.initialDealMessages.pushObject(updatedMessage);
                    taskFor(this.markAsRead).perform();
                }
            }
            this.sortAndFilterDealMessages();
        }
    }

    sortAndFilterDealMessages() {
        this.sortedAndFilteredDealMessages = this.initialDealMessages.sortBy('createdAt').filterBy('id');
    }
}
