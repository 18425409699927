import Deal from 'portal/models/deal';
import FeatureFlag from 'portal/services/feature-flag';
import RouteInfo from '@ember/routing/-private/route-info';
import Transition from '@ember/routing/-private/transition';
import window from 'ember-window-mock';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { reactUrl } from 'portal/helpers/react-url';

const dealRoutesImplementedInNewPortal = [
    'protected.deal',
    'protected.deal.index.my-deal',
    'protected.deal.index.to-do',
    'protected.deal.index.documents',
    'protected.deal.index.messages',
    'protected.deal.index.people'
];

const activityRoutesImplementedInNewPortal = [
    'protected.deal.onboarding.seller-closing-preferences',
    'protected.deal.onboarding.seller-titleholder-info'
];

const activityRoutesActionItemTemplateKeys: Record<string, ActionItemTemplateKeys> = {
    'protected.deal.onboarding.seller-closing-preferences': ActionItemTemplateKeys.SELLER_CLOSING_PREFERENCES,
    'protected.deal.onboarding.seller-titleholder-info': ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS
};

export const maybeRedirectToNewPortalBeforeModel = (transition: Transition, featureFlag: FeatureFlag) => {
    const dealId = findParameter(transition.to, 'deal_id');
    if (shouldRedirectToNewPortalDeal(transition.to, featureFlag) && dealId !== undefined) {
        redirectToNewPortalDeal(transition.to, dealId);

        //We return a never fulfilling promise to block routing from continuing and loading unneeded models until the browser has redirected
        return new Promise(() => { return; });
    }

    return;
};

export const maybeRedirectToNewPortalAfterModel = (model: Deal, transition: Transition, featureFlag: FeatureFlag) => {
    const routeName = transition.to?.name;
    if (activityRoutesImplementedInNewPortal.includes(routeName)) {
        if (
            routeName === 'protected.deal.onboarding.seller-closing-preferences' &&
            !featureFlag.enabled('react_seller_closing_preferences')
        ) {
            return;
        }

        if (
            routeName === 'protected.deal.onboarding.seller-titleholder-info' &&
            !featureFlag.enabled('react_seller_owner_information')
        ) {
            return;
        }

        const actionItem = model?.actionItems?.find((a) => a.templateKey === activityRoutesActionItemTemplateKeys[routeName]);
        if (actionItem) {
            redirectToNewPortalActivity(transition.to, model.id, actionItem.id);

            // We return a never fulfilling promise to block routing from continuing
            // and showing the old page until the browser has redirected
            return new Promise(() => { return; });
        }
    }

    if (shouldRedirectToNewPortalDeal(transition.to, featureFlag)) {
        redirectToNewPortalDeal(transition.to, model.id);

        //We return a never fulfilling promise to block routing from continuing and showing the old page until the browser has redirected
        return new Promise(() => { return; });
    }

    return;
};

const shouldRedirectToNewPortalDeal = (route: RouteInfo, featureFlag: FeatureFlag): boolean => {
    return areNewPortalDealPagesEnabled(featureFlag) && isDealRouteImplementedInNewPortal(route);
};

const isDealRouteImplementedInNewPortal = (route: RouteInfo): boolean => {
    return dealRoutesImplementedInNewPortal.includes(route?.name);
};

const areNewPortalDealPagesEnabled = (featureFlag: FeatureFlag): boolean => {
    return featureFlag.enabled('react_deal_page');
};

const redirectToNewPortalDeal = (route: RouteInfo, dealId: string) => {
    const path = route?.name ==  'protected.deal' || route?.name == 'protected.deal.index.my-deal'
        ? reactUrl(`deals/${dealId}`)
        : reactUrl(`deals/${dealId}/${route?.localName}`);

    const url = new URL(path);
    const queryParameters = collectQueryParameters(route);
    Object.entries(queryParameters).forEach(([key, value]) => url.searchParams.append(key, value));

    window.location.assign(url);
};

const redirectToNewPortalActivity = (route: RouteInfo, dealId: string, actionItemId: string) => {
    const path = reactUrl(`deals/${dealId}/action-items/${actionItemId}`);

    const url = new URL(path);
    const queryParameters = collectQueryParameters(route);
    Object.entries(queryParameters).forEach(([key, value]) => url.searchParams.append(key, value));

    window.location.assign(url);
};

const findParameter = (route: RouteInfo, key: string): string | undefined => {
    if (route.params[key] !== undefined) return route.params[key]!;
    else if ( route.parent !== null) return findParameter(route.parent, key);
    return undefined;
};

const collectQueryParameters = (route: RouteInfo, priorParameters: Record<string, string> = {}): Record<string, string> => {
    const filteredParams = Object.fromEntries(
        Object.entries(route.queryParams).filter(([, value]) => value !== undefined) as [string, string][]
    );

    const newParameters = { ...priorParameters, ...filteredParams };
    return route.parent !== null ? collectQueryParameters(route.parent, newParameters) : newParameters;
};





