import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ProtectedDealNewIndexNewMessages extends Controller {
    queryParams = ['newMessageText', 'shouldScroll'];

    newMessageText?: string = '';

    @tracked
        shouldScroll!: string;

    @action
    scrollTo(element: HTMLDivElement) {
        if (this.shouldScroll === 'true') {
            window.scrollTo(0, element.offsetTop);
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/index/messages': ProtectedDealNewIndexNewMessages;
  }
}
