import Controller from '@ember/controller';
import IntlService from 'ember-intl/services/intl';
import ProtectedDealNewIndex from 'portal/controllers/protected/deal/index';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default class ProtectedDealIndexNewPeople extends Controller {
    @service intl!: IntlService;

    @service
    declare router: RouterService;

    @controller('protected/deal/index')
    declare newIndexController: ProtectedDealNewIndex;

    @action
    goToMessages() {
        this.newIndexController.currentSection = this.intl.t('components.deal.sections.messages');
        this.router.transitionTo('protected.deal.index.messages');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/index/people': ProtectedDealIndexNewPeople;
    }
}
