import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealNewIndexPeople extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Index / People');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as Deal;
        await deal.hasMany('opsTeamUsers').reload();
        const opsTeamUsers = deal.opsTeamUsers?.toArray();
        return {
            deal,
            opsTeamUsers
        };
    }
}
