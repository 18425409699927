import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { BufferedChangeset } from 'validated-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedDealOnboardingSellerClosingPreferencesRoute
} from 'portal/routes/protected/deal/onboarding/seller-closing-preferences';
import { dealSellerValidations } from 'portal/models/deal';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProtectedDealOnboardingSellerClosingPreferences extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingSellerClosingPreferencesRoute>;

    @service
    declare router: RouterService;

    get validations(): any {
        return dealSellerValidations;
    }

    @task
    async saveDeal(changeset: BufferedChangeset): Promise<void> {
        if (changeset.isInvalid) {
            throw new Error(
                `Deal is invalid: ${JSON.stringify(changeset.errors)}`
            );
        }

        await changeset.save();

        const actionItems = await this.model?.actionItems;
        const actionItem = actionItems?.find((a) => a.templateKey == ActionItemTemplateKeys.SELLER_CLOSING_PREFERENCES);

        if (actionItem) {
            actionItem.hasCompleted = true;
            actionItem.save();
            this.router.transitionTo('protected.deal.action-item-completion', actionItem.id);
        } else {
            this.router.transitionTo('protected.deals');
        }

    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/onboarding/seller-closing-preferences': ProtectedDealOnboardingSellerClosingPreferences;
  }
}
