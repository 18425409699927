import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealIndexMessages extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Index / Messages');
    }

    queryParams = {
        shouldScroll: {
            refreshModel: true
        }
    };

    async model() {
        const currentUser = await this.auth.getAuthenticatedUser();

        const deal = this.modelFor('protected.deal') as Deal;
        await deal.hasMany('messages').reload();
        const messages = deal.messages?.toArray();
        return {
            currentUser,
            deal,
            messages
        };
    }

    resetController(controller: any, isExiting: boolean) {
        if (isExiting) {
            controller.set('shouldScroll', false);
        }
    }
}
